.search .loading-container {
    border: 1px solid #202f4c;
    border-radius: 10px;
    margin-block: 10px ;
    height: 330px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.home .loading-container {
    /* border: 1px solid #202f4c; */
    border-radius: 10px;
    
    box-shadow: 0px 2px 5px rgba(146, 146, 146, 1);
    margin-block: 10px ;
    height: 330px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
 .loading-container{
    margin-block: 10px ;
    height: 300px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.spinner-border{
    width: 100px;
    height: 100px;
}

.signup-form .spinner-border {
    width: 20px;
    height: 20px;
}