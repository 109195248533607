.heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.add-btn{
    cursor: pointer;
    width: 30px;
    height: 30px;
}
.save-btn{
    cursor: pointer;
    background-color: blue;
}