.detail-list .selected-item {
    background-color: white !important;
    color: #12306b !important; 
  }
  

.builder-detail-left{
    display: flex;
    gap: 20px;
}
.builder-detail-right ul{
    margin-bottom: 0;
}
.builder-detail-section {
    border: 1px solid #202f4e;
}
.builder-detail-right ul li{
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px;
}
.builder-detail-left img{
    height: 80px;
}
.project-content{
    margin-top: 100px;
}
.customer-image{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.project-content .slider-for  img {
    height: 200px;
    width: 230px;
    object-fit: cover;
    border: 2px solid #12306c;
    border-radius: 10px;
}
.project-content .slider-for{
    margin-top: 20px;
}
.our-project-tabs{
   
        position: absolute;
        right: 130px;
        top:150px;
        width: 200px;
        margin-top: 20px;
        margin-bottom: -40px;
     
}
.our-project-tabs select{
    background-color: #12306b;
    color: white;
    font-size: large;
    text-align: center;
}
.our-project-tabs select:focus{
    background-color: #12306b;
    color: white;
}

ul.detail-list{
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
}
.detail-list li:hover{
    background-color: white;
    color: #12306b;
    cursor: pointer;
}
.detail-list-dropdown{
    display: none;
}
.detail-list li{
    background-color: #12306b;
    border-radius: 10px ;
    padding: 10px 20px;
    color: white;
    font-size: large;
    border:2px solid #12306b;
    transition:  0.3s ease-in-out ;
    font-weight: 500;
}

.total-units ul li {
    display: inline-block;
    width: 33.33%;
    margin-left: -4px;
    text-align: center;
    padding: 10px;
    font-size: 20px;
    border: 1px solid #6d7077;
}
.total-units ul {
    margin-bottom: 10px;
}
.total-units ul li:nth-child(1) {
    text-align: left;
    background-color: #12306c;
    color: #ffffff;
}
.total-units ul li:nth-child(2) {
    background-color: #59d0e4;
    color: #12306c;
}
.total-units ul li:nth-child(3) {
    background-color: #dce2ee;
    color: #12306c;
}
@media (max-width:768px){
    .our-project-tabs{
        right: 10px;
        top:220px;
     
}

ul.detail-list{
    display: none;
}
  
    .detail-list-dropdown {
      display: block;
      margin-bottom: 10px; 
    }
  
    .detail-list-dropdown select {
      width: 100%;
      padding: 8px;
    }
  
 h4{
    margin-top: 10px;
}
.project-content{
    margin-top: 50px;
}

    .builder-detail-left{
        gap:10px ;
    }
    .builder-detail-right{
        margin-top: 10px;
    }
    .total-units{
        padding-block: 10px;
    }
    .total-units  ul{
        padding-left: 0 ;
    }
    .total-units ul li{
        font-size: 16px;
        padding: 5px;
    }
    .total-units ul li:nth-child(1) {
        width:45%;
    }
    .total-units ul li:nth-child(2) {
        width: 25%;
    }
    .total-units ul li:nth-child(3) {
        width: 30%;
    }
  
}