
.profile-section {
  border: 1px solid #202f4e;
}
.pre-booking-right ul{
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
}
.pre-booking-right .selected-items {
  color: white !important;
  background-color: #12306b !important; 
}
.profile-section .row{
    display: flex;
    flex-wrap: wrap;
}
.profile-section .row .child1{
    flex: 40%;
}
.profile-section .row .child2{
    flex: 60%;
}
.profile-section .profile-datail ul li{
    display: inline-block;
    padding-inline: 2px;
    vertical-align: middle;
}
.profile-section .profile-datail ul li img{
  max-width: 150px;
  max-height: 100px;
}

.profile-section .profile-datail ul{
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.profile-section .profile-datail ul li p{
   margin-bottom: 0;
   font-size: 14px;
}
.profile-section .profile-datail ul li a{
    text-decoration: none;
 }
 .profile-section .profile-datail ul li .edit-profile{
    color: #012060;
 }
.profile-section .profile-datail ul li ul{
    padding-left: 0;
 }
.add-documents {
    color: #ffffff;
    background-color: #12306c;
    padding: 10px 5px;
    cursor: pointer;
    display: inline-block;
    border-radius: 10px;
    line-height: normal;
    text-transform: uppercase;
    font-size: 12px;
}

.navbar-toggle {
  border: none;
  background: transparent !important;
}
.navbar-toggle:hover {
  background: transparent !important;
}
.navbar-toggle .icon-bar {
  width: 22px;
  transition: all 0.2s;
}
.navbar-toggle .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}
.navbar-toggle .middle-bar {
  opacity: 0;
}
.navbar-toggle .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
}
.navbar-toggle.collapsed .top-bar {
  transform: rotate(0);
}
.navbar-toggle.collapsed .middle-bar {
  opacity: 1;
}
.navbar-toggle.collapsed .bottom-bar {
  transform: rotate(0);
}

.navbar-toggle .icon-bar {
    display: block;
    width: 30px;
    height: 5px;
    border-radius: 1px;
}
.navbar-default .navbar-toggle .icon-bar {
    background-color: #12306c;
}
.navbar-toggle .icon-bar+.icon-bar {
    margin-top: 4px;
}
.nav-menu button {
    border: 0;
}
.buyer-pre-booking-section .prebooking{
    margin-block: 20px;
    border: 0px solid;
    border-radius: 10px;
    background-color: #faf8f8;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    font-size: small;
    line-height: 1px;
}
.buyer-pre-booking-section .prebooking .home-loan-detail{
background-color:#12306b;
color: white;
padding: 1rem;
margin-top: 2rem;
}
.prebooking p{
  font-size: 1rem;
  line-height: 1rem;
}
.prebooking h5 {
  text-align: center;
  text-decoration: underline;
}
.buyer-pre-booking-section .prebooking .apply-btn{
    margin-block: 2rem;
}
.buyer-pre-booking-section .prebooking .apply-btn a{
    text-decoration: none;
    color: #112f6b;
    font-size:15px;
    font-weight: bold;
    border-radius: 5px;
    padding: 5px;
    background-color: white ;
}
.buyer-pre-booking-section .pre-booking-right .hello {
    display: flex;
    border: none;
    align-items: center;
    width: 100vw;
    font-weight: bold ;
}
.buyer-pre-booking-section .pre-booking-right ol li{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}
.buyer-pre-booking-section .pre-booking-right table {
    width: 100%;
    border-collapse: collapse;
    border: 2px solid #ccc;
  }
  .buyer-pre-booking-section .pre-booking-right th {
    padding: 8px;
    font-weight: 500;
    text-align: center;
    border: 2px solid #ccdd;
  }
  .buyer-pre-booking-section .pre-booking-right  td{
    text-align: center;
    border: 1px solid #ccc;
  }
.buyer-pre-booking-section .pre-booking-right  button{
    background-color: #12306c;
    border-radius: 5px;
    color: white ;
    cursor: pointer;
    margin : 5px 0;
}

.wishlist-images{
    color:#012060;
    margin-top: 20px;
    padding: 5px;
    background-color:#dae4f5 ;
}
.wishlists-items{
    margin: 5px 10px;
    padding-top: 10px ;
    padding-inline: 25px;
    border: 2px solid #012060;
    background-color: #faf8f8;
    color: #012060;
}
.wishlists-items img{
    width: 100%;
    height: 150px;
    border: 2px solid #0d2e7b;
}
.wishlist-images p{
    margin-top: 20px;
    text-align: center;
}
.wishlist-images h6{
   text-align: center;
}
.banner img {
    width: 100%;
    height: 100px;
    object-fit: cover;
  }

.pre-booking-right{
    margin-top: 20px;
}
.pre-booking-right ul li{
    text-align: center;
    width: 50%;
    border: 1px solid #ccc;
    padding: 10px;
    text-transform: uppercase;
    font-weight: 600;
    transition: all 0.3s ease-out;
}
.pre-booking-right ul li:hover{
  color: white;
  background-color: #12306c;
  cursor: pointer;
}
.pre-booking-right ul li a{
    color: #12306c;
    text-transform: uppercase;
    font-size: 18px;
    border: 1px solid #707070;
    padding: 10px 0;
    display: block;
    text-align: center;
    -webkit-transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    -ms-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
    transition: all 0.2s ease;
}
.pre-booking-right ul li a:hover{
    background-color: #12306c;
    color: #ffffff;
    border-color: #12306c;
}
.dropbtn {
    background-color: transparent;
    color: white;
    font-size: 16px;
    border: none;
  }
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #012060;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    right: 0;
  }
  
  .dropdown-content a {
    color: #ffffff;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {background-color: #ddd; color: #012060;}
  
  .dropdown:hover .dropdown-content {display: block;}
  ul.header-right li{
    display: inline-block;
  }
  .dropbtn img{
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 100%;
  }
  .error-page{
    margin:50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
/* buyer booking close */
.requirements{
  font-size: 1rem;
}
.requirement p{
  margin-block: 5px;
}
.requirements label {
  margin-inline: 5px;
}
.requirement-fields{
  padding: 5px;
}
.requirement{
  padding: 10px;
  border: 1px solid #012060;
  border-radius: 10px;
  background-color: white ;
}



@media (max-width: 576px) {
  .buyer-pre-booking-section .pre-booking-right td,th {
    padding: 0;
    font-size: 10px !important;
   }

   .wishlist-images img{
    width: 100% !important;
    height: auto;
  }
}
@media (max-width: 768px) {
  .dropdown-content {
    
    min-width: 50vw;
    
  }   
  .pre-booking-right ul {
    font-size: 12px;
  }
  .pre-booking-right ul li{
    padding: 5px;
  }
  .dropbtn img{
    width: 50px !important;
    height: 50px !important;
  }
  .profile-datail ul{
    display: flex;
    padding-left: 0;
    align-items: center;
    justify-content: space-around;
    gap: 20px;
  }
  .buyer-pre-booking-section .prebooking{
    margin-block: 0;
  }
  .profile-datail ul li:nth-child(3){
    display: none;
  }
 .profile-datail ul li:nth-child(4){
    display: none;
  }
.pre-booking-right{
  margin-top: 0;
}
  .buyer-pre-booking-section .row{
    flex-direction:column-reverse;
    width: 100vw;
  }
  .buyer-pre-booking-section .pre-booking-right .hello {
    width: 94vw;}
  .pre-booking-right ul li a {
    font-size: 1rem;
  }
  .buyer-pre-booking-section .container{
    margin: 0;
  }
  .wishlist-images img{
    width: 80%;
    height: 200px;
  }
  .wishlist-images p{
    margin-block: 10px;
}
  .pre-booking-right ul li{
    display: inline-block;
    width: 50%;
    padding-right: 0px;
}
.buyer-pre-booking-section .pre-booking-right td,th {
 padding: 0;
 font-size: 12px;
}

}