.home-cal #donutChart{
    width: 200px !important;
    height: 200px !important;
 }

.home-cal label,span {
    font-size: 14px;
}
.home-cal .calcultor-form{
    margin: 10px 50px;
}
.home-cal .chart-value{
    display: flex;
    gap: 20px;
}
.chart-value .value {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
 .home-cal .calcultor-form .occupation-option{
    
    gap: 100px;
         } 
.our-location {
    border: 1px solid #202f4c;
    border-radius: 10px;
    margin-block: 10px ;
    padding: 15px;
}

.our-flats li {
    float: left;
    width: 28%;
    margin: 0 10px
}
.our-flats li img.img-responsive{
    border-radius: 10px;
    width: 100%;
    height: 200px;
    overflow: hidden;
}
.our-flats li:first-child{
	margin-left: 0;
}
.our-flats li.our-group {
    width: 10%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
}

li.our-group li {
    display: block;
    width: 100%;
    margin: 0;
    float: inherit;
    margin-bottom: 15px;
}
 .our-flat-detail{
	margin-top: 15px;	
}

.our-location p {
    font-size: 20px;
    color: #12306c;
    margin-bottom: 15px;
}
.our-flat-detail p{
	margin: 0;
	font-size: 16px
}
.our-flat-detail p span {
    text-decoration: line-through;
}
.our-flat-detail p span.red{
	text-decoration:none;
	color: #e50e1e;
}
.our-banking {
    border: 5px solid #12306c;
    border-radius: 10px;
}
.banking-detail {
    background-color: #12306c;
    padding: 20px;
}
.banking-logo ul {
   display: grid;
   grid-template-columns: auto auto auto auto;
}
.banking-logo ul li{
    text-align: center;
    
 }
.banking-logo li  img{
    display: inline-block;
    width: 200px;
}
.banking-section {
    padding-bottom: 25px;
}
.banking-text h2{
	text-transform: uppercase;
	color: #ffffff;
	font-weight: 500;
}
.banking-text p{
	color: #ffffff;
	font-size: 18px;
    font-weight: 500;
}
.banking-text p.margin-15{
	margin-top: 15px;
}
.banking-text {
    float: left;
    width: 70%;
}
.start-now-btn {
    float: right;
    width: 30%;
}
.start-now-btn a {
    background-color: #ffffff;
    padding: 10px;
    border-radius: 10px;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 20px;
    font-weight: 700;
    color: #12306c;
    transition:  color 0.1s, font-size 0.1s;
}
.start-now-btn a:hover {
    color: rgb(25, 4, 100);
    font-size: 20.5px;
}
.banking-logo ul {
    padding: 7px;
}
.mall-title h1 {
    background-color: #f3e635;
    color: #2863d7;
}
.genuine-service p {
    color: #12306c;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
}
.genuine-product {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.genuine-product img{
    width: 150px;
    height: 150px;
    border-radius: 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
object-fit: cover;
}
.genuine-product a {
    color: #113069;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    margin-top: 10px;
    display: inline-block;
}

.offer-board {
    padding-top: 50px;
}
.our-offer {
    background-color: #12306c;
    padding: 30px;
}
.our-offer p {
    color: #ffffff;
    font-size: 16px;
}
p.offer {
    display: inline-block;
    margin-left: 2rem;
}

.buying-home {
    padding-top: 30px;
    padding-bottom: 30px;
}

.home-with-us {
    background-color: #12306c;
    padding: 50px;
}
.home-with-us h2 {
    color: #f4e942;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 20px;
}
.home-with-us ul li {
    list-style-type: decimal;
    color: #ffffff;
}
.home-with-us ul li p {
    color: #ffffff;
}
.customer-testimonial {
    border: 1px solid #707070;
    padding: 0 20px !important;
}


.our-flats ul {
    padding-left: 0;
}
.testimonial-slider {
    padding-left: 10px;
    padding-bottom: 20px;
}
.customer-testimonial h5 {
    margin: 10px 0 20px 0;
    color: #12306c;
    font-weight: 600;
    font-size: 24px;
}
.customer-review p {
    color: #12306c;
    font-weight: 500;
    font-size: 16px;
}



.middle-section .property-buttons{
    display: none;
}
.our-flats li img.img-responsive {
    border-radius: 10px;
    object-fit: cover;
}
.filtering .results{
    display: none;
}

.advantage-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .advantage-card {
    width: calc(50% - 20px); /* 50% width for two cards per row with some spacing */
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 20px;
    height: 150px;
    border-radius: 20px;
    margin: 10px 0;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
  }
  
  .advantage-card:hover {
    cursor: pointer;
    transform: scale(1.01);
  }
  
.customer-speaks h3, p {
    color: #08070f;
}
 .slick-prev::before{
    color: #12306c;
}
 .slick-next:before{
    color: #12306c;
}
@media (max-width: 576px) {
    .banking-detail{
        flex-direction: column;
        align-items: center;
    }
    .banking-section {
        padding-top: 0px;
        padding-bottom: 10px;
    }
    
    .start-now-btn{
        width: 100%;
        margin-top: 0;
    }
    .banking-text{
        width: 100%;
    }
    .banking-logo ul {
        display: grid;
        grid-template-columns: auto auto !important;
        
     }
    .dream-home{
        margin-top: 10px;
    }
    .genuine-service .services{
        margin-top: 0 !important;
        display: grid !important;
        grid-template-columns: auto auto;
    }
    .start-now-btn a {
        padding: 5px;
        font-size: 15px;
    }
    .our-flats ul{
        padding-left: 0;
    }
    .our-flats ul {
        width: 100%;
        height: auto;
    }
    .our-flat-detail{
        width: 100%;
        margin-top: 0px;	
    }
    .our-group ul{
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        justify-content: space-between;
        width: 70vw;
     
    }
    .our-flats li.our-group {
        width: 100%;
        height: auto;
        padding: 10px;
        
    }
    .our-flats ul li .clickableimage img{
        height: 200px !important;
    }
    
 
  }
  
  /* Small Devices (e.g., tablets) */
  @media (max-width: 768px) {
    .container{
        max-width: 100vw;
    }
    .start-now-btn a {
        padding: 5px;
    }
    .our-offer .offer{
        margin-block: 0;
    }
    .our-offer, .home-with-us{
        padding: 20px;
    }
    .our-offer p {
        color: #ffffff;
        font-size: 14px;
    }
    .banking-text p{
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 10px;
    }
    .banking-logo li  img{
        width: 100px;
    }
    .genuine-product{
        margin-top: 10px;
    }
    .genuine-product a {
        margin-top: 0;
    }
    .offer-board, .buying-home{
        padding-top: 20px;
    }
    .buying-home{
        margin-top: 0;
        padding-bottom: 0;
    }
    .buying-home h2{
        font-size: 20px;
    }
    .buying-home h3{
        font-size: 18px;
    }
    .our-flats .slick-next:before{
        display: none;
    }
    .our-flats .slick-prev::before{
        display: none;
    }
    .advantage-list {
        display: flex;
        flex-direction: column;
        
      }
      
    
    .our-flats ul li{

        width: 100%;
        height: auto;
    }
    .advantage-card {
        width: calc(100% - 5px); 
        padding: 10px;
        height: 130px;
      }
    .customer-speaks{
        padding: 0 20px;
        margin-bottom: 10px !important;
    }
    .customer-speaks h1{
        margin-bottom: 10px !important;
    }
    .home-cal #donutChart{
        width: 200px !important;
        height: 200px !important;
     }
    
    
    
    .home-cal .calcultor-form{
        margin: 10px ;
        padding: 10px;
    }
    .home-cal .calcultor-form .form-result{
        margin-left: 0;
    }
    .home-cal .chart-value{
        display: block;
        gap: 0;
    }
    
}
  
  /* Medium Devices (e.g., small laptops) */
  @media (max-width: 992px) {
    .container{
        max-width: 100vw;
    }
    .our-flats ul {
        padding-left: 0;
    }
    .our-flats li img.img-responsive {
        
        width: 100%;
        height: auto;
        
    }
    .banking-logo ul {
        display: grid;
        grid-template-columns: auto auto  auto;
        
     }
    .our-group ul{
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        align-items: center;
        justify-content: space-between;
        width: 70vw;
     
    }
    li.our-group li {
        margin-bottom: 0;
        display: flex;
        justify-content: center;
    }
   
  }
  

  