/* site visit page  */
.site-visit-detail{
    padding-block: 50px;
}
.site-visit-detail h5{
    font-size: 16px;
}
.site-visit-reschedule-detail h5{
    font-size: 16px;
}
.site-visit-detail .buttons {
    padding: 5px ;
}
.site-visit .site-visit-detail .site-visit-left{
    width: 40%;
}
.site-visit .site-visit-detail .site-visit-right{
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.site-visit-left  img{
    width: 100% !important;
    height: 300px !important;
    object-fit: cover;
    border: 4px solid #012060;
    border-radius: 10px;
}
.site-visit-right .form-group{
    width: 100%;
}
.date-box-container, .time-slot-container{
    display: grid;
    text-align: center;
    grid-template-columns: repeat(7, 1fr);
    gap: 20px;
}
.date-box {
    cursor: pointer;
    border: 2px solid grey;
    border-radius: 10px;
    
    padding: 5px;
}
.time-slot {
    cursor: pointer;
    border: none;
    padding: 5px;
}
.date-box p{
    text-align: center;
    margin-bottom: 0;
}

.form-group .selected {
    margin: 0 ;
    padding: 5px;
    border-radius: 10px;
    color: white !important;
    background-color: #012060;
   
}
.date-box-container .selected p{
    color: white ;
}


@media (max-width:576px) {
    .time-slot-container{
        grid-template-columns: repeat(3, 1fr) !important;
    }
}
@media (max-width: 768px) {
    .site-visit .site-visit-detail .site-visit-left{
        width: 100%;
    }
    .site-visit .site-visit-detail .site-visit-right{
        width: 100%;
        margin-top: 20px;
    }
    .date-box-container{
        grid-template-columns: repeat(3, 1fr);
        gap: 5px;
    }
    .time-slot-container{
        grid-template-columns: repeat(4, 1fr) !important;
        gap: 5px;
    }
}
/* site visit page end  */

/* site reschedule page :start */
.site-visit-reschedule-detail{
    padding-block: 30px;
}
.site-visit-reschedule .row{
    align-items: center;
}
@media (max-width: 768px) {
    .site-visit-reschedule-detail{
        padding-block: 0;
    }
}
/* site reschedule page end  */


/* sitevisit confirmation page start */

.site-visit-confimation-detail{
    display: flex;
    justify-content: space-around;
    padding-block: 30px;
    gap: 20px;
}
.site-visit-confimation-detail p {
    font-weight: 500;
    font-size: 1.4rem;
}
.site-visit-confimation-detail span {
    font-weight: 500;
    font-size: 1.4rem;
}
.site-confirmation-left{
    width: 60%;
    text-align: start;
    display: flex;
    flex-direction: column;

    justify-content: center;
}
.site-confirmation-right{
    width: 40%;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.site-confirmation-right img{
    width: 100% !important;
    height: 350px !important;
    object-fit: cover;
    border: 4px solid #012060;
    border-radius: 10px;
    
}

@media (max-width:768px){
    .row{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .site-visit-confimation-detail p {
        font-size: 16px;
    }
    .site-visit-confimation-detail span{
        font-size: 16px;
    }
    .site-visit-detail{
        padding-block: 0;
    }
    .site-visit-confimation-detail{
        flex-wrap: wrap-reverse;
        padding-block: 0;
    }
    .site-visit-left  img{
        height:  250px !important;
        border: 2px solid #012060;
    }
    .site-confirmation-right  img{
        height:  250px !important;
        border: 2px solid #012060;
    }
    .site-confirmation-right, .site-confirmation-left{
        padding-block: 0;
        width: 100%;
    }
    .site-image{
        width: 80vw;
    }
}

/* sitevisit confirmation page end */


/* Assign Site Visit page start  */
.date-filter{
    display: flex;
    gap: 10px;
}
.assign-sitevisits .filter-card{
    display: flex;
    gap: 10px;
    justify-content: space-between;
    padding: 10px;
    border-radius: 10px;
    background-color: #edf0f5;
}
.assign-sitevisits table {
    width: 100%;
    border-collapse: collapse;
    margin-block:20px;
    border: 2px solid #d3c9c9;
  }
  .assign-sitevisits th {
    padding: 8px;
    font-weight: 500;
    text-align: center;
    background: #edeef8;
    border: 2px solid #ccdd;
  }
  .assign-sitevisits  td{
    text-align: center;
    padding: 5px;
    border: 1px solid #ccc;
  }
  .assign-sitevisits tr:nth-of-type(2n){
    background: #f5f7fa;
  }



  @media (max-width:768px){
    .assign-sitevisits .filter-card{
        justify-content: space-between;
    }
  .assign-sitevisits .container{
    max-width: 100Vw ;
  }
  .assign-sitevisits select {
    font-size: 10px;
  }
    .date-filter{
        flex-direction: column;
        gap: 0;
    }
      .assign-sitevisits th {
        padding: 0px;
        font-weight: 500;
      }
      
      .assign-sitevisits table :nth-child(5){
        display: none;
      }
      .assign-sitevisits  td{
        text-align: center;
        /* display: block; */
        padding: 5px;
        font-size: 10px;
        border: 1px solid #ccc;
      }
}

.site-visit-cancellation{
    padding: 30px 0; 
    text-align: center;
}
.site-visit-cancellation-image img {
    width: 100% !important;
    height: 300px !important;
    object-fit: cover;
    border: 4px solid #012060;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .site-visit-cancellation{
        padding:  0; 
        text-align: center;
    }
    .site-visit-cancellation-image img {
        height: 250px !important;
}}