
.navbar{
  padding-block: 15px;
}
.signin-btn span {
  font-size: 20px;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: uppercase;
  
}
.signin-btn span a{
  text-decoration: none;
  color: #012060;
  font-weight: bold;
  position: relative;
}

.signin-btn span a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: rgb(25, 4, 100);
  transition: width 0.3s;
}

.signin-btn span a:hover {
  color: rgb(25, 4, 100);
}

.signin-btn span a:hover::after {
  width: 100%;
}
ul.header-right{
  margin: 0;
  padding: 0;
}
.header-top {
  background-color: #012060;    
}



@media (max-width: 576px) {
  .header-top .signin-btn li{
    font-size: 10px;
  }
  .header-right li:nth-child(1){
      display: none;
  }
 .header-top .navbar{
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  /* display:none !important ; */
 
 }
 .header-top .container{
  max-width: 100vw;
  padding: 0;
 }

  .dropdown .dropbtn img {
      width: 60%;
      height: 60%;
      border-radius: 100%;
  }
}

@media (max-width:768px) {
  .navbar{
    padding: 10px;

  }
  .signin-btn span {
    width: 90px;
    
    font-size: 14px;
    display: flex;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 5px 10px;
    text-transform: uppercase;
    
  }
  .navbar .logo img{
    max-width: 80%;
  }
}
