.progress-bar {
    width: 100%;
    height: 40px;
    background-color: #ccc;
    margin-block: 20px;
  }
  
  .progress {
    height: 100%;
    background-color: #f9f9fa;
    display: flex;
    border:2px solid #012060;
    color: #012060;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  
  .step-label {
    flex: 1;
    text-align: center;
    color: #012060;
    font-size: 16px;
    font-weight: bold;
    padding: 5px;
    transition: background-color 0.3s;
  }
  
  .completed {
    background-color:lightblue; 
    height: 40px;
    
    display: flex;
    align-items: center;
    justify-content: center;/* Change the color for completed steps */
  }
  
  @media (max-width:768px) {
    .progress-bar{
      margin-block: 0;
    }
    
    
  }