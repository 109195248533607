.footer-section{
    background-color: #e1e0ee;
   
}
.footer-section footer{
	background-color: #e1e0ee;
	padding: 10px 50px;

}
.left-footer a{
    color: darkblue;
    text-decoration: none;
}
.right-footer a {
     text-decoration: none;
     color: darkblue;
}
.right-footer input {
    background-color: #e1e0ee;
    outline: none;
    border: 1px solid #08070f;
    border-radius: 3px;
    padding: 4px 8px;
    margin: 15px 0;
}
.right-footer h4 {
    font-size: 14px;
    margin-bottom: 10px;
}
.coppyright {
    margin-top: 50px;
}