.builder-confirmation{
    text-align: center;
    background-color: #e1e0ee;
    padding: 30px 60px;
    margin: 50px;
    width: 500px;
    background: linear-gradient(to right, rgb(45, 135, 170), rgb(160, 219, 214));
    border-radius: 10px;
    box-shadow: 20px 20px 20px #888888;
}
.builder-confirmation p{
    font-size: large;
}
.builder-confirmation button {
    background-color:white ;
    padding: 5px 20px;
    border-radius: 10px;
}
.builder-confirmation button:hover {
    background-color: rgb(48, 191, 196);
    transition: 0.3s ease-in-out;
}