
 .filter-card{
    display: flex;
    gap: 10px;
    justify-content: space-between;
    padding: 10px;
    border-radius: 10px;
    background-color: #edf0f5;
}
.project-details table {
    width: 100%;
    border-collapse: collapse;
    margin-block:20px;
    border: 2px solid #d3c9c9;
  }
  .project-details th {
    padding: 8px;
    font-weight: 500;
    text-align: center;
    background: #edeef8;
    border: 2px solid #ccdd;
  }
  .project-details  td{
    text-align: center;
    padding: 5px;
    border: 1px solid #ccc;
  }
  .project-details tr:nth-of-type(2n){
    background: #f5f7fa;
  }



  @media (max-width:992px){
    .filter-card label{
        font-size: small;
    }
    .filter-card select{
        font-size: small;
    }
    .project-details  th{
        display: none;
       
      }
    .project-details  td{
        text-align: start;
        display: grid;
        text-transform: capitalize;
        grid-template-columns: 30ch auto;
        border: none;
      }
      .project-details tr{
        border: 1px solid #ccc;
      }
      .project-details td:nth-of-type(1)::before{
        content: "SR No. : ";
      }
      .project-details td:nth-of-type(2)::before{
        content: "Project Name : ";
      }
      .project-details td:nth-of-type(3)::before{
        content: "City : ";
      }
      .project-details td:nth-of-type(4)::before{
        content: "Layout : ";
      }
      .project-details td:nth-of-type(5)::before{
        content: "Unit No. : ";
      }
      .project-details td:nth-of-type(6)::before{
        content: "Status : ";
      }
      .project-details td:nth-of-type(7)::before{
        content: "Basic rate per sqft : ";
      }
      .project-details td:nth-of-type(8)::before{
        content: "Extra charge per sqft : ";
      }
      .project-details td:nth-of-type(9)::before{
        content: "GST : ";
      }
      .project-details td:nth-of-type(10)::before{
        content: "Saledeed value : ";
      }
      .project-details td:nth-of-type(11)::before{
        content: "Legal fee : ";
      }
      .project-details td:nth-of-type(12)::before{
        content: "Other charges : ";
      }
      .project-details td:nth-of-type(13)::before{
        content: "Stamp duty : ";
      }
      .project-details td:nth-of-type(14)::before{
        content: "Registration fee : ";
      }
      .project-details td:nth-of-type(15)::before{
        content: "Price : ";
      }


}
@media (max-width: 576px) {

.project-details  td{
        grid-template-columns: 25ch auto;
       
      }
}