
.unit-type-filter{
    width: 150px;
  }
  
  @media (max-width:992px){
  
      .layout-details  td{
          text-align: start;
          display: grid;
          text-transform: capitalize;
          grid-template-columns: 30ch auto;
          border: none;
        }
        .layout-details tr{
          border: 1px solid #ccc;
        }
        .layout-details td:nth-of-type(1)::before{
          content: "SR No. : ";
        }
        .layout-details td:nth-of-type(2)::before{
          content: "Nickname : ";
        }
        .layout-details td:nth-of-type(3)::before{
          content: "BHK : ";
        }
        .layout-details td:nth-of-type(4)::before{
          content: "Unit type : ";
        }
        .layout-details td:nth-of-type(5)::before{
          content: "Reracarpert Area : ";
        }
        .layout-details td:nth-of-type(6)::before{
          content: "Builtup Area : ";
        }
        .layout-details td:nth-of-type(7)::before{
          content: "Superbuiltup Area : ";
        }
        .layout-details td:nth-of-type(8)::before{
          content: "Layout Image : ";
        }
        .layout-details td:nth-of-type(9)::before{
          content: "Comments : ";
        }
        
        
        }
  