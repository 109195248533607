.search{
    margin-top: 20px;
}
.property-search .projectCard ul li{
    background-color: #12306c;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    padding: 10px ;
    margin-bottom: 10px;
    border-radius: 10px;
    cursor: pointer;
}
.property-search .projectCard ul{
    padding-left: 0;
}

.property-search  .projectCard ul li a {
    text-decoration: none;
    color: white;
}
.property-search .projectCard  .project-images img{
        height: 180px;
        width: 220px;
        object-fit: cover;
        border: 2px solid #12306c;
        border-radius: 10px;
    }
.property-search .projectCard .builder-logo  img{
    width: 100px;
    object-fit: cover;
}
.property-search .projectCard ul .area-registerd{
    display: none;

}

@media (max-width : 768px) {
    .search{
        margin-top: 0;
    }
.property-search .project-images{
    height: 300px;
    width: 80vw;
    }
    .property-search .slick-slider{
        /* margin: 20px; */
        width: 85%;
    }
    .property-search .slick-slide img{
        height: 200px;
        width: 250px;
        object-fit: cover;
        border: 2px solid #12306c;
        border-radius: 10px;
    }
    .area-registerd{
        padding-right: 0;
    }
    .property-search .projectCard .our-flat-detail{
        margin-bottom: 10px;
    }
    .property-search .projectCard ul{
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 10px;
    }
    
    .property-search .projectCard ul .area-registerd{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 0;
                grid-row
                : 2 / 5;
        
    }
    .property-search .projectCard ul li{
        padding: 5px;
    }

}