
.projectCard {
    /* border: 1px solid #202f4c; */
    border-radius: 20px;
    margin-block: 10px ;
    padding: 15px;
    
    box-shadow: 0px 2px 5px rgba(146, 146, 146, 1);
}
.possession{
    text-transform: uppercase;
    color: #12306c;
}
.img-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.project-images {
    display: flex;
    justify-content: space-between;
}
.project-images img{
    height: 200px;
    width: 250px;
    object-fit: cover;
    border: 2px solid #12306c;
    border-radius: 10px;
}
 .builder-logo{
    display: flex;
    align-items: center;
    justify-content: center;
}
.home .builder-logo  img{
   
    height: 100px;
    object-fit: cover;
}
.projectCard ul{
    padding-left: 0;
    margin: 10px;
}
.projectCard .our-flat-detail{
	display: flex;	
    justify-content: space-between;
    align-items: center;
}
.projectCard ul li img{
    height: 200px;
    width: 300px;
    border: 2px solid #12306c;
    border-radius: 10px;
    object-fit: cover;
}




@media (max-width:768px) {
    .projectCard{
        padding: 0 15px;
    }
    .size-decription, .short-decription {
        font-size: 14px;
    }
    .slick-prev, .slick-next{
        display: none !important;
    }
    .slick-initialized .slick-slide {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }
   .home .builder-logo img{
        height: 60px !important;
    }
   .rera-registerd img{
        height: 30px !important;
    }
     .area-registerd {
        display: none;
    }
    .area-registerd img{
        height: 30px;
    }
    
    .amenities{
        margin-bottom: 10px;
        flex-wrap: wrap;
    }
    .amenities span{
        font-size: 14px;
    }
}
@media (max-width:992px) {
    .project-images img{
        height: 170px;
        width: 190px;
        object-fit: cover;
        border: 2px solid #12306c;
        border-radius: 10px;
    }
    .home .builder-logo img {
        height: 80px;
        object-fit: cover;
    }
    
}