
.heading{
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
}
.heading h3 {
    margin-bottom: 0;
}
.detail{
    display: flex;
    justify-content: flex-start;
    gap: 100px;
    align-items: center;
}
.detail-left{
    width: 300px;
    
}
.detail-right p{
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 600;
}
.detail-right button{
    background-color: #12306c;
    color: white;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 20px;

}
.history img{
    height: 100px;
    width: 100px;
    object-fit: cover;
    border: 2px solid #12306c;
    border-radius: 5px;
}

.add-const-status{
        display: grid;
        gap: 10px 30px;
        grid-template-columns: repeat(2, 1fr);
    }

    .block-filter{
        width: 100px;
    }