.dashboard .options{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 50px;
}
.dashboard .add-project {
    margin-block: 0;
    margin-bottom: 20px;
}
.options-btn{
    color: #002060;
    border-radius: 10px;
    padding: 10px;
    border: 2px solid #002060;
    cursor: pointer;
    background-color: white;
    transition: 0.3s ease-in-out;
}
.selected{
    color: #fff;
    background-color: #002060;
}
/* .options-btn:hover{
     color: white;
    background-color: #002060;
} */

.navigate-btn{
    color: white;
    background-color:#002060;
    padding: 5px;
    text-decoration: none;
    border-radius: 5px;
}

.stepper {
    .line {
      width: 2px;
      background-color: lightgrey !important;
    }
    .lead {
      font-size: 16px;
    }
  }


 