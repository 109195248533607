/*project detail start*/
.image-slider{
    width: 500px;
    height: 300px;
    overflow: hidden;
    
}
.image-slider .carousel img{
    object-fit: cover;
}

.area-map {
    padding: 25px 0;
}
.map-detail {
    border: 2px solid #707070;
    text-align: center;
    border-radius: 10px;
    height: 300px;
    overflow: hidden;
}
.map-detail img {
    width: 100%;
   height: 100%;
   object-fit: cover;
}
.map-detail .clickableimage {
    width: 100%;
    height: 100%;
   object-fit: cover;
}
.map-detail p {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #707070;
    margin-bottom: 0px;
    padding: 5px 0;
    color: #162f71;
}
.micro-detail-box {
    border: 1px solid #707070;
    padding: 10px 50px;
    margin-bottom: 30px;
    border-radius: 10px;
}
.micro-detail-box p {
    color: #173679;
}
.micro-detail-box p span {
    display: block;
    font-size: 18px;
}
.bank-offering h4{
    text-transform: uppercase;
    font-size: 26px;
    color: #112f6b;
    text-align: center;
    font-weight: 700;
}


.quick-loan ul li {
    float: left;
    width: 25%;
    text-align: center;
}
.other-banks {
    background-color: #12306c;
    display: inline-block;
    padding: 20px 10px;
}
.other-banks a{
    color: #ffffff;
}
.show-bank{
    display: none;
}
.show-bank.bank-show{
    display: block;
}
li.other-banks {
    display: none;
}

.pre-sanction  a {
    display: inline-block;
    text-align: right;
    width: 49%;
    color: #1b315a;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
}
.pre-sanction  a:hover{
    text-decoration: underline;
}
.rera-section{
    border: 0px solid;
    border-radius: 10px;
    background-color: #faf8f8;
    margin: 30px 0;
    padding: 20px 20px;
}


.rera-page ul{
    display: flex;
    align-items: center;
    padding-left:0 ;
    justify-content: space-between ;
}
.rera-page ul li {
    display: inline-block;
}
.rera-page ul li p {
    font-size: 22px;
    color: #12306c;
}
.rera-page ul li a {
    background-color: #12306c;
    color: #ffffff;
    padding: 10px 22px;
    text-decoration: none;
    border-radius: 10px;
    display: inline-block;
}
.legal-documents p {
    font-size: 20px;
    color: #12306c;
}
.legal-documents select{
        border: 2px solid #12306c;
        border-radius: 5px;
        padding: 5px 10px;
        width: 80%;
        color: #12306c;
        font-weight: 500;
    
}
.home-buying{
    margin-top: 20px;
}
.home-buying p{
    font-size: 18px;
    color: #193062;
}

ul.area-selection  {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
}
ul.area-selection li:nth-child(1){
    width: 50%;
}
.location-whatsapp{
    display: flex;
    align-items: center;
}
.location-whatsapp a {
    background-color: #12306c;
    color: #ffffff;
    text-decoration: none;
    display: inline-block;
    padding: 6px 10px;
    border-radius: 10px;
    font-size: 16px;
}
ul.area-show li {
    display: inline-block;
    width: 49%;
    vertical-align: middle;
}
ul.area-show li p{
    text-transform: uppercase;
    font-size: 18px;
}

.about-builder-section {
    border: 0px solid;
    border-radius: 10px;
    background-color: #faf8f8;
    margin: 30px 0;
    padding: 20px 20px ;
}
.about-builder-detail{
    margin: 0 0 50px; 
}
.about-builder-detail p {
    margin: 10px 0;
    font-size: 20px;
    color: #12306e;
}
.about-locality-detail p {
    font-size: 20px;
    color: #12306e;
}
.about-locality-detail h6 {
    font-size: 20px;
    color: #12306e;
    margin-bottom: 20px;
}
.about-builder-detail h6 {
    margin: 20px 0;
    font-size: 20px;
    color: #182f61;
    margin-bottom: 20px;
}

ul.area-show li p{
    color: #182f61;
    font-size: 16px;
}
ul.area-show li img{
    width: 200px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
}
ul.area-show li p.margin{
    margin: 15px 0;
}
.customer-speak {
    margin-top: 20px;
    padding-top: 0;
    margin-bottom: 30px;
    border: 0px solid #707070;
    border-left: 0;
    border-right: 0;
}
.customer-speak.project-detail .customer-testimonial {
    border:0;
    padding: 0px;
}

.project-detail-slider section .customer-image  {
    border: 2px solid  #12306c;
    border-radius: 10px;
    height: 300px; 
   
}
.project-detail-slider section .customer-image img{
    width: 100%;
    object-fit: cover;
}
.project-detail-slider button.slick-arrow.slick-next{
    z-index: 1;
}
.project-detail-slider section .customer-image  video{
    /* border: 2px solid  #12306c; */
    border:3px solid #002060;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.project-detail-slider section .customer-image img{
    height: 100% !important;
    border: none !important;
    border-radius: 0 !important;
}
.project-detail-slider-left p {
    text-align: center;
    color: #12306c;
    font-size: 18px;
    margin-bottom: 20px;
    text-transform: uppercase;
}
.project-detail-slider-left .our-builder{
 display: flex;
 justify-content: center;
 
 align-items: center;
}
.our-builder img{
    width: 200px;
    object-fit: cover;
    height: auto;
}
.details-images{
    max-width: 90%;
}
.project-detail-slider-left ul li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
}
.our-locations p {
    font-size: 20px;
    color: #12306c;
    margin: 15px;
}
.project-detail-slider-right-button {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.project-detail-slider-right-button ul {
   padding-left: 0;
}
.project-detail-slider-right-button ul li a {
    background-color: #12306c;
    color: #ffffff;
    text-decoration: none;
    align-items: center;
    text-transform: uppercase;
    padding: 12px 25px;
    display: block;
    margin-bottom: 10px;
    border-radius: 10px;
}
.project-detail-slider-right-button ul li.whatsapp-btn a {
    background-color: #ffffff;
    color: #12306c;
    text-transform: uppercase;
    padding: 0;
    display: block;
    margin-bottom: 0;
    border-radius: 0;
}
.project-detail-slider-right-button ul li {
    text-align: center;
    text-decoration: none;
}
.project-detail-slider-right-button ul li.whatsapp-btn {
    margin-top: 30px;
}
ul.area-selection select {
    border: 2px solid #12306c;
    border-radius: 5px;
    padding: 5px 10px;
    color: #12306c;
    font-weight: 500;
}
.about-builder-detail p {
    margin: 10px 0;
    font-size: 18px;
}
.about-locality-detail p {
    font-size: 18px;
}
.home-buying p a {
    float: right;
    color: #12306c;
    font-style: italic;
}
.area-show img{
    width: 200px;
}
.rera-page p span{
    word-wrap: break-word;
    
}
ul.clearfix{
    padding-left: 0;
}
.legal-documents {
    position: relative;
  }
  
  .legal-documents ul li {
    font-size: 20px;
    color: #12306c;
    list-style: auto;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content:  space-between;
    padding-right: 300px;
  }
  .legal-documents  button{
    border-radius: 10px;
    margin-block: 2px;
    color: white;
    background-color: #12306c;
  }
  
  .rera-page p span{
    font-size: 18px;
}
.map-detail .clickableimage img{
    height:100% !important;
    border-radius: 0 !important;
    border: none !important;
    object-fit: cover !important;
}


/* Extra Small Devices (e.g., smartphones) */
@media (max-width: 576px) {
    .project-detail-slider section .customer-image {
        border: 2px solid #12306c;
        height: 200px;
        width: auto;
        overflow: hidden;
    }
    
    .project-detail-slider-left p.possession{
        margin: 0;
    }
    .project-detail-slider-right-button ul li a {
        width: 80vw;
    }
    .micro-detail-box {
        border: 2px solid #707070;
        border-radius: 5px;
        padding: 10px 20px;
        margin-bottom: 10px;
    }
    .micro-detail-box p span {
        display: block;
        margin-left: 0;
        font-size: 16px;
    }
    
    .map-detail{
        margin: 10px;
    }
    .area-map {
        padding: 0 ;
    }
    .rera-section{
        margin-top: 0;
        margin-bottom: 0;
    }
    
    .legal-documents ul li{
        margin-left: 0;
        padding-right: 0;
        font-size: 1rem;
    }
    .about-builder-detail h6 {
        margin-bottom: 10px;
    }
    ul.area-selection{
        padding-left: 0 ;
        font-size: 16px;
    }
    ul.area-selection li{
        width: 100%;
       padding-block: 10px;
    }
    .location-whatsapp{
        display: flex;
        justify-content: space-around ;
    }
    .quick-loan ul{
        display: grid !important;;
        grid-template-columns: repeat(2, 1fr) !important;
        align-items: center;
    }
  }
  
  /* Small Devices (e.g., tablets) */
  @media (max-width: 768px) {
    .project-detail-slider-left{
        margin-top: 30px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .details-images {
        position: relative;
    }
    .project-detail-slider-right-button ul {
       display: grid;
       grid-template-columns: auto auto;
       column-gap: 50px;
       align-content: center;
    }
  
    .project-detail-slider-right-button ul li:nth-child(5){
        display: flex;
        align-items: center;

        margin-top: 0;
            grid-row
            : 2 / 5;
    }
    .project-detail-slider-right-button ul li.whatsapp-btn img{
        width: 30px;
        height: 30px;
        object-fit: cover;
       
    }
    .project-detail-slider-right-button ul li a{
        padding: 5px ;
        width:45vw;
    }
   
    .project-detail-slider-left ul {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0;
    }
    .project-detail-slider section .customer-image img{
        width: 300px;
        border: none !important;
        border-radius: 0 !important;
    }
    .project-detail-slider section .customer-image video{
        width: 300px;
    }
    .clickableimage img{
        height: 50vw !important;
    }
    .our-builder img {
        width: 100px;
    }
    .our-locations p {
        text-align: center;
        font-size: 18px;
        color: #12306c;
        margin-top: 0;
        margin-inline: 0;
    }
    .micro-detail-box p span{
        font-size: 16px;
    }
    .map-detail{
        height: 150px;
        width: 150px;
    }
    .micro-detail{
        margin-top: 20px;
    }
    .bank-offering h4{
        font-size: 20px;
        margin-block: 0;
    }
    .quick-loan {
        padding: 0;
    }
    .quick-loan ul{
        padding: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
    .quick-loan ul li{
        padding: 10px;
        width: 100%;
        align-self: center;

        justify-self: center;
       }
    
    .quick-loan ul li img{
        width: 100%;
        align-self: center;
        justify-self: center;

    }
    .rera-page {
        padding: 0;
    }
    
    .rera-page ul{
        padding: 0;
    }
    .rera-page p{
        display: flex;
        flex-direction: column;
    }
    .rera-page p span{
        font-size: 16px;
    }
    .home-buying .area-show li {
        width: 100%;
        padding: 0;
    }
    ul.area-selection{
        margin-right: 0;
        justify-content: space-between;
    }
    ul.area-show li img{
        width: 150px;
        height: 100px;
        object-fit: cover;
    }
    .location-whatsapp button{
    color: white;
    padding: 5px;
    font-size: 12px;
    margin-left: 0px
    }
    .location-whatsapp i{
        font-size: 16px !important;
    }
    .location-whatsapp a {
        padding: 5px;
        border-radius: 10px;
        font-size: 12px;
    }
    .legal-documents ul{
        padding: 0;
    }
    .about-builder-section{
        margin: 0;
    }
    .about-builder-detail{
        margin-bottom: 20px;
    }
    .about-builder-detail h6{
        margin-top: 0;
    }
    .about-locality-detail h6 {
        margin-bottom: 10px;
    }
    .customer-speak{
        margin-top: 0;
    }
    .testimonial-slider{
        padding: 0;
    }
    .legal-documents ul li{
        padding-right: 0;
    }
   
}
  
  /* Medium Devices (e.g., small laptops) */
  @media (min-width: 769px) and  (max-width: 992px) {
    .project-detail-slider-left ul{
        padding-left: 0;
       
    }
    .project-detail-slider-right-button ul{
        margin-bottom: 0;
       
    }
    .project-detail-slider-right-button ul li.whatsapp-btn{
        margin-top: 0;
       
    }
    .project-detail-slider-right-button ul li.whatsapp-btn p{
        margin: 0;
       
    }
    .project-detail-slider-right-button ul li a{
        padding: 5px;
    }
    .map-detail{
        height: 200px;
    }
    .micro-detail-box{
        padding: 10px;
    }
    .micro-detail-box p span{
        margin: 0;
        font-size: 16px;
    }
    .legal-documents ul li{
        padding-right: 0;
    }
    .quick-loan ul{
        padding: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
  }
