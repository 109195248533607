.star-rating {
    font-size: 50px;
    color: #ccc;
    cursor: pointer;
 }
.stars {
    color: #fdd835; /* Material Design Yellow 600 */
    font-size: 1.5rem;
    cursor: pointer;
    transition: color 0.2s;
  }
  
  .star.active {
    color: #ffeb3b; /* Material Design Yellow 500 */
  }
  
  .mb-3 {
    margin-bottom: 1.5rem;
  }
 .feedback-form h3{
     text-align: center;
     margin-top: 2rem;
 }
 .feedback-form h2{
    text-align: center;
    margin-block: 2rem;
}
.form-section{
    display: flex;
    align-items: center;
}
.feedback-form label{
    font-size: 20px;
}
.star-rating span{
    font-size: 15px;
}
.feedback-form .submit{
    display: flex;
    margin: 20px;
    justify-content: center;
    /* align-items: center; */
}

@media (max-width: 768px) {
    .container {
      padding: 10px;
    }
  
    .form-label {
      text-align: left;
      margin-bottom: 5px;
    }
  
    .form-section {
      flex-direction: column;
      align-items: flex-start;
    }
  .form-section span{
    margin-left: 20px;
  }
    .star-rating {
      font-size: 24px;
    }
  
    .feedback-form .submit {
      text-align: center;
    }
  }
  