.unit-details ::-webkit-scrollbar {
  display:flex !important ;
}

@media (max-width:992px){

   .dashboard .all-units .filter-card{
    display: grid !important;
    grid-template-columns: auto auto auto !important ;
  }
    .Unit-details  td{
        text-align: start;
        display: grid;
        text-transform: capitalize;
        grid-template-columns: 30ch auto;
        border: none;
      }
      .Unit-details tr{
        border: 1px solid #ccc;
      }
      .unit-details td:nth-of-type(1)::before{
        content: "SR No. : ";
      }
      .unit-details td:nth-of-type(2)::before{
        content: "layout : ";
      }
      .unit-details td:nth-of-type(3)::before{
        content: "Block : ";
      }
      .unit-details td:nth-of-type(4)::before{
        content: "Floor : ";
      }
      .unit-details td:nth-of-type(5)::before{
        content: "Floor range : ";
      }
      .unit-details td:nth-of-type(6)::before{
        content: "Full Unit Number : ";
      }
      .unit-details td:nth-of-type(7)::before{
        content: "Unit Superbuiltup Area : ";
      }
      .unit-details td:nth-of-type(8)::before{
        content: "Status : ";
      }
      .unit-details td:nth-of-type(9)::before{
        content: "Facing of Gate : ";
      }
      .unit-details td:nth-of-type(10)::before{
        content: "Sunlight : ";
      }
      .unit-details td:nth-of-type(11)::before{
        content: "View from Flat : ";
      }
      .unit-details td:nth-of-type(12)::before{
        content: "Open direction : ";
      }
      .unit-details td:nth-of-type(13)::before{
        content: "Basic rate per sqft : ";
      }
      .unit-details td:nth-of-type(14)::before{
        content: "Extra charges per sqft : ";
      }
      .unit-details td:nth-of-type(15)::before{
        content: "Legal fees : ";
      }
      .unit-details td:nth-of-type(16)::before{
        content: "Stamp duty percent : ";
      }
      .unit-details td:nth-of-type(17)::before{
        content: "Registration fees percent : ";
      }
      .unit-details td:nth-of-type(18)::before{
        content: "Registration fees for female buyers : ";
      }
      .unit-details td:nth-of-type(19)::before{
        content: "Maintenance deposit per sqft : ";
      }
      .unit-details td:nth-of-type(20)::before{
        content: "Adv. maintenance per sqft : ";
      }
      .unit-details td:nth-of-type(21)::before{
        content: "Adv. maintenance number of months : ";
      }
      .unit-details td:nth-of-type(22)::before{
        content: "Price comment : ";
      }
      }