
.profile-settings table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border: none;
  }
  .profile-settings th {
   
    text-align: left;
    padding: 8px;
    text-align: center;
    background: white;
    border: none;
  }
  .profile-settings td:nth-of-type(1){
    text-align: left;
  }
  .profile-settings td {
    text-align: center;
    padding: 8px;
    border: none;
  }

  .profile-settings tr:nth-of-type(2n){
    background: white !important ;
  }