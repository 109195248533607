.profile {
    display: flex;
}

.profile-right{
    width: 100%;
    padding: 10px 0px 200px 10px;
}
.profile .profile-icons{
    display: flex;
    justify-content: flex-end;
  
}
.profile-icons button{
    background-color:none;
    color: black;
    
    border: none;
}
.profile-photo img{
    object-fit: cover;
    width: 200px;
    height: 200px;
    border-radius: 50%;
}
.profile-photo {
    display: flex;
    align-items: center;
    justify-content: center;
}
.profile-detail{
    display: flex;
    margin-left: 100px;
    /* justify-content: space-between; */
    gap: 100px;
}
.profile-detail p{
    font-size: large;
    
}
.file-input-label {
    display: inline-block;
    position: relative;
    cursor: pointer;
  }
  
  .file-input-box {
    width: 100px; /* Set the width and height as per your design */
    height: 100px;
    background-color: #eee; /* Set the background color */
    border: 2px dashed #ccc; /* Set the border */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px; /* Adjust the font size as needed */
    color: #777; /* Adjust the font color as needed */
  }
  
  /* Style the file input to be hidden */
  #file-input {
    display: none;
  }
  
  /* Style the "+" icon */
  i.fas.fa-plus {
    font-size: 36px; /* Adjust the font size of the "+" sign */
  }
  

@media (max-width: 768px)  {
    .profile-detail{
        flex-direction: column !important;
        gap: 20px !important;
        margin-left: 0;
    }
}