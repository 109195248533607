.remove-button {
    display: none;
}
.add-project {
    margin-block: 40px;
    background-color: #e1e0ee;
    padding: 20px;
    background: linear-gradient(to top, rgb(67, 166, 205), rgb(69,214,202));
    border-radius: 10px;
    
}

.add-project:hover {
    background-color: #dcdcf4;
    box-shadow: 5px 5px 10px #888888;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s, background-color 0.3s;
}


.add-project .form-controls {
    background-color: transparent;
    border-radius: 10px;
    border: 2px solid #636a70;
    width: 100%;
    padding: 5px;
    border-radius: 10px;
}
.add-project .form-controls:focus {
    outline: none; /* Remove default outline */
    border-color: #0078d4;
     /* Change border color on focus */
    box-shadow: 2px 2px 5px #636a70; /* Add a subtle shadow on focus */
}

.add-project label{
    font-size: large;
    font-weight: 500;
}
.add-project h2{
    margin-bottom: 20px;
}



/* AddImages page  */


.add-images .images .input-fields{
    display: grid;
    gap: 10px 30px ;
    grid-template-columns: repeat(4, 1fr);
}
.add-images .videos .input-fields{
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(3, 1fr);
}
.add-images .videos{
    margin-top: 30px;
}
.add-project .form-control{
  border: 2px solid #888888;
}
@media (max-width:1200px) {
    .add-images .images .input-fields{
    grid-template-columns: repeat(3, 1fr);
}}
@media (max-width:992px) {
    .add-images .images .input-fields{
    grid-template-columns: repeat(2, 1fr);
}
.add-images .videos .input-fields{
    grid-template-columns: repeat(1, 1fr);
}
}
@media (max-width:768px) {
    .add-images .images .input-fields{
    grid-template-columns: repeat(1, 1fr);
}
.add-project .project-detail{
    grid-template-columns: repeat(1, 1fr) !important;
}
.add-files .input-fields{
    grid-template-columns: repeat(1, 1fr) !important;
}

}
/* add files page  */

.add-files .input-fields{
    display: grid;
    gap: 10px 30px ;
    grid-template-columns: repeat(2, 1fr);
}


/* AddProjectDetail page  */

.add-project .project-detail{
    display: grid;
    gap: 10px 30px ;
    grid-template-columns: repeat(2, 1fr);
}

