.App {
  text-align: center;
}

.container{
  max-width: 1250px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
ul { 
  list-style-type: none !important;
  text-decoration: none;
  padding-left: 0;
}
/* ::-webkit-scrollbar {
  display: none;
} */
p{
  margin-bottom: 0;
}
.buttons{
  background-color: #002060;
  color: white;
  font-size: 1.2rem;
  width: 150px;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-block:20px;
  border: 2px solid #d3c9c9;
}
th {
  padding: 8px;
  font-weight: 500;
  text-align: center;
  background: #edeef8;
  border: 2px solid #ccdd;
}
td{
  text-align: center;
  padding: 5px;
  border: 1px solid #ccc;
}
tr:nth-of-type(2n){
  background: #f5f7fa;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
