
.find-home ul{
    padding-inline: 10px;
}
.find-home ul li {
    display: inline-block;
    width: 18%;
    margin-block: 10px;
    padding: 0px 5px;
}

.find-home ul li label {
    display: block;
    color: #212e4e;
    font-weight: 500;
}
.find-home ul li select{
    background-color: white ;
    border-color: hsl(0, 0%, 80%);
    height: 40px;
}
.find-home ul li:nth-child(6){
    margin-top: 30px;
}
.find-home ul li.submit-btn {
    width: 40px;
    height: 40px;
    background-color: #12306c;
    border: 2px solid #12306c;
    color:  white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    transition: all 0.2s ease-in;
}
.prebooking .find-home ul li.submit-btn {
  width: 30px !important;
  height: 30px !important;
}
.find-home ul li.submit-btn:hover {
   
   color: #12306c;
   background-color: white;
   
   
}
.multi-ranges-slider {
    border: none !important;
    border-radius: 10px;
    padding: 20px 20px !important;
    box-shadow: none !important;
  }
.find-home ul li.submit-btn a{
    display: flex;
    color: white;
    transform: scale(1.52);
}
.find-home ul li.submit-btn a:hover{
    color: #12306c;
}
.possession span{
    font-size: 16px !important;
    font-weight: 600 !important;
}
.find-home {
    border: 6px solid #12306c;
    border-radius: 8px;
    padding: 0px 0;
}
.find-home ul li.submit-btn input{
	background-color: #dce2ee;
	border: 1px solid #19305c;
	color: #212e4e;
	border-radius: 5px;
}
.find-your-home .find-home ul{
    display: flex;
    justify-content: space-between ;}
    .requirement .find-home ul{
        flex-direction: column;
        padding: 5px;
        margin: 0;
      }
      .requirement .find-home{
        margin-top: 0;
      }
        .requirement .find-home ul li{
       width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between ;
      }
      .requirement .find-home ul li.submit-btn{
        margin-top: 10px;
        justify-content: center;
        align-items: center;
       }
      .requirement .find-home ul li.submit-btn button{
        padding: 10px; 
        color: white;
        background-color:#2e4d8d;
       }
      .requirement .find-your-home .results{
        display: none;
      }
      
      .range{
        display: none;
      }
      
      
      .multi-ranges-slider {
        border: none !important;
        border-radius: 10px;
        padding: 20px 20px !important;
        box-shadow: none !important;
      }
      
      .home .modal-content{
        position: relative !important;
        /* top: 50px; */
        width: 80vw;
        top : 620px !important;
        padding: 20px !important;
        left: 100px;
        background-color: white;
        border-radius: 10px;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
      }
      .home .results{
        display: none;
      }
      .property-search .modal-content{
        position: absolute !important;
        top: 180px;
        width: 80vw;
        padding: 20px !important;
        left: 8vw;
        z-index: 1;
        background-color: white;
        border-radius: 10px;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
      }
      .prebooking .input-field {
        width: 170px;
      }
      .prebooking .modal-content{
        position: absolute !important;
        top: 500px;
        width: 80vw;
        padding: 20px !important;
        left: 8vw;
        background-color: white;
        border-radius: 10px;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
      }
      .requirement .modal-content{
        top :450px;
        left: 400px;
        width: 800px;
      }
      .requirement .find-home ul li:nth-child(6){
        display: flex;
        align-self: center;
        justify-content: center;
      }
      .budget{
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        border-radius: 5px;
        border: 1px solid  hsl(0, 0%, 80%);
        position:  relative;
      }
      .prebooking .budget{
        width: 170px;
      }
      .apply{
        position: absolute  ;
        right: 0 ;
        top: 0;
        cursor: pointer;
        transform: scale(1);
        padding: 5px 10px;
      }

@media (max-width:768px) {
  .property-search .find-home ul{
    margin-bottom: 0;
    flex-wrap: wrap;
    /* font-size: 10px; */
  }
  .property-search .find-home ul li{
    font-size: 10px;
    width: 33%;
    margin-top: 0;
  }
  .property-search .possession {
    margin-bottom: 10px;
  }
  .property-search .find-home ul li:nth-child(4){
    width: 40%;
  }
  .property-search .find-home ul li:nth-child(5){
    width: 40%;
  }
  .property-search .find-home ul li:nth-child(6){
    margin-top: 15px;
  }
  .property-search .find-home {
    border: 2px solid #12306c;
    border-radius: 8px;
    padding: 0px 0;
}
.prebooking .property-search .find-home ul li:nth-child(4){
  width: 100%;
}
.prebooking .property-search .find-home ul li:nth-child(5){
  width: 100%;
}
.prebooking .input-field {
  width: 80%;
}
.prebooking .budget{
  width: 80%;
}

.css-1xc3v61-indicatorContainer{
  padding: 0 !important;
}
.requirement .modal-content {
  top: 700px;
  left: 40px;
  width: 300px;
}
.requirement .find-home ul li{
  width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between ;
 }
 /* .requirement .find-home ul li .input-field{
  width: 80%;
 } */
.property-search .find-home ul li.submit-btn {
  width: 30px;
  height: 30px;
  background-color: #12306c;
  border: 2px solid #12306c;
}

.property-search .budget span{
  font-size: 10px;
}
.find-home ul li select {
  font-size: 10px;
}
}