.booking-id-date p {
    font-size: 18px;
    color: #12306c;
    font-weight: 500;
}
.buyer-bank-detail h3{
    color: #12306c;
    margin-block: 0 20px;
}
.buyer-bank-detail p{
    margin-left: 3vw;
    font-size: 18px;
    color: #12306c;
    font-weight: 500;
    margin-bottom: 0;
}
.buyer-bank-detail p a {
    color: #ffffff;
    background-color: #12306c;
    padding: 10px 25px;
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 10px;
}
.booking-property-detail h3{
    color: #12306c;
    margin-block: 25px 20px;
}
.booking-property-detail p{
    margin-left: 3vw;
    font-size: 18px;
    color: #12306c;
    font-weight: 500;
    
}
.booking-property-detail p a {
    color: #ffffff;
    background-color: #12306c;
    padding: 10px 25px;
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 10px;
}
.booking-property-detail ul{
    display: flex;
    justify-content: space-around;
    margin: 30px
}
.booking-property-image img{
    width: 100%;
    height: auto;
}
.booking-property-detail ul li {
    display: inline-block;
    margin-right: 100px;
}
.booking-property-detail ul li a {
    border: 1px solid #707070;
    border-radius: 10px ;
    text-decoration: none;
    padding: 15px 30px;
    color: #12306c;
    font-size: 18px;
    font-weight: 500;
    display: inline-block;
}
:hover .booking-property-detail ul li a:hover {
 
    background-color:#12306c;
    transition: all 0.3s ease-in-out;
    color: white;
   
}
.bookings{
    margin-block: 20px;
    border: 0px solid;
    border-radius: 10px;
    background-color: #faf8f8;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    font-size: small;
}