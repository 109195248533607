@media (max-width:992px){
  
    
      .booking-page tr{
        border: 1px solid #ccc;
      }
      .booking-page td:nth-of-type(1)::before{
        content: "SR No. : ";
      }
      .booking-page td:nth-of-type(2)::before{
        content: "Unit No. : ";
      }
      .booking-page td:nth-of-type(3)::before{
        content: "Buyer Name : ";
      }
      .booking-page td:nth-of-type(4)::before{
        content: "Co-Buyer Name : ";
      }
      .booking-page td:nth-of-type(5)::before{
        content: "Date of booking : ";
      }
      .booking-page td:nth-of-type(6)::before{
        content: "KYC-ATS : ";
      }
      .booking-page td:nth-of-type(7)::before{
        content: "Home loan : ";
      }
      .booking-page td:nth-of-type(8)::before{
        content: "Payment due : ";
      }
      .booking-page td:nth-of-type(9)::before{
        content: "Payment Received : ";
      }
      }
