
@media (max-width:992px){
  .dashboard .all-blocks .filter-card{
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
  }
    .block-details  td{
        text-align: start;
        display: grid;
        text-transform: capitalize;
        grid-template-columns: 30ch auto;
        border: none;
      }
      .block-details tr{
        border: 1px solid #ccc;
      }
      .block-details td:nth-of-type(1)::before{
        content: "SR No. : ";
      }
      .block-details td:nth-of-type(2)::before{
        content: "Project : ";
      }
      .block-details td:nth-of-type(3)::before{
        content: "Block : ";
      }
      .block-details td:nth-of-type(4)::before{
        content: "Total Floors : ";
      }
      .block-details td:nth-of-type(5)::before{
        content: "Units on each floor : ";
      }
      .block-details td:nth-of-type(6)::before{
        content: "Total units in block : ";
      }
      .block-details td:nth-of-type(7)::before{
        content: "Edit block details : ";
      }
      .block-details td:nth-of-type(8)::before{
        content: "Delete block : ";
      }
      }