
/*login signup form start*/

   
.contforsignin{
    display: flex;
    align-items: center;
    justify-content: center;
}
.signup-form {
    border: 1px solid #202f4e;
    margin: 40px 0;
    padding: 50px;
    border-radius: 10px;
}
.signup-form .form-group .form-control{
	border: 2px solid #12306c;
    border-radius: 10px;
    color: #12306c;
    box-shadow: none;
}
.signup-form .form-group a{
    text-decoration: none;
    padding: 20px;

}
.form-group .signup{
	background-color: #12306c;
    color: #ffffff;
    border: 0;
    padding: 10px 20px;
    font-weight: 600;
    width: 100px;
    border-radius: 5px;
}

.signup-form.width-50 {
    border: 0;
    margin: 50px auto;
    background-color: #e1e0ee;
}
.account-link p a{
    color: #12306c;
    font-weight: 600;
}
.form-group{
    margin-bottom: 10px;
}

.form-group.password {
    position: relative;
  }
  
  .form-group.password input {
    padding-right: 30px; /* Adjust padding to accommodate the eye icon */
  }
  
  .form-group.password i {
    position: absolute;
    top: 50%;
    right: 15px; 
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 15px;
  }
  
  .forgot-pass-form{
    width: 500px;
    
  }

  @media (max-width:768px) {
    .signup-form.width-50 {
        margin: 20px;
        padding: 30px;
    }
    .contforsignin{
        display: block;
    }
  }