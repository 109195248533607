.calculator{
    background-color: #002060;
    color:  white   ;
    padding: 20px;
    border-radius: 10px;
}
.calculator label{
    font-weight: 500;
}
.calculator p{
    color: white;
}
.calcultor-form{
    border-radius: 10px;
    margin: 50px 30px 10px;
    padding: 20px;
    background-color: white;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}
.calcultor-form .form-result{
    background-color: #d4ddf1;
    border-radius: 10px;
    padding: 30px 15px ;
    margin-left: 10px;
}
.form-result span{
    font-weight: 500;
    font-size: 16px;
}
.form-result p{
    color: black ;
    font-weight: 500;
    font-size: 20px;
}
.form-result .line{
        border-right: 2px solid #002060;
      }
.form-result button{
    color: white;
    margin-top: 10px;
    background-color: #002060;
    border: 2px solid #002060;
    border-radius: 20px;
    padding: 5px 20px;
    transition: 0.3s ease-in-out;

}
.form-result button:hover{
    background-color: white;
    color:  #002060;
   

}
.calculator .form-control {
    display: block;
    width: 50%;
    margin-top: 0 !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    border-radius: none;
    background-color:rgb(236, 236, 236);
    background-clip: padding-box;
}
.chart{
    display: flex;
    justify-content: center;
    align-items: center;
 }

#donutChart{
    width: 250px !important;
    height: 250px !important;
 }
.monthly-emi{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(243, 243, 147);
    padding: 5px;
    border: 2px solid #002060;
    border-radius: 20px;
    
}
.monthly-emi p{
    font-size: 16px;
}
.monthly-emi span{
    font-size: 20px;
}

 .calcultor-form .form-result{
    padding: 10px;
    margin-left: 0;
}
 .calcultor-form .occupation-option{
display: flex;
gap: 50px;
     }    .calcultor-form .occupation{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
 }


 @media (max-width: 768px) {
    .calculator{
        padding: 10px;
    }
    .calcultor-form{
        margin: 10px;
    }
 }