
.project-name{
    display: flex;
    padding: 10px;
    gap: 20px;
    border: 1px solid #012060;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: #faf8f8;
    /* box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2); */
    
}
.project-name .buyer-detail-image img{
    height: 100px !important;
}



/* select unit page start */
/* .toggle-criteria-btn{
    display: none;
}
.criteria-btn{
    display: none;
} */

@media (max-width:768px) {
   
    /* .toggle-criteria-btn {
        display: block;
        padding: 5px;
        margin-top: 10px;
        background-color: #012060;
        color: white;
        border: none;
        border-radius: 10px;
    } */
    .project-name{
        margin-bottom: 0;
    }
    .project-name .buyer-detail-image img{
        height: 80px !important;
    }
    /* .criteria-btn {
        display: flex;
        justify-content: space-between;
    }
    .criteria-btn button{
        padding: 5px ;
        background-color: #012060;
        color:  white;
        border: none;
        border-radius: 10px;

    } */
}


/* buyer form page  */

.buyer-detail{
    padding: 20px 15px;
}
.buyer-detail-text h2{
    color: #12306c;
}
.buyer-detail .buyer-detail-image img{
    width: 100% !important ;
    height: 200px !important;
    object-fit: cover !important;
    border: 2px solid #012060;
    border-radius: 10px;
    
}
.buyer-form-data .form-group{
    margin-inline: 10px;
}
.buyer-form-data .form-group .form-control{
    border: 1px solid #12306b;
    border-radius: 0;
    color: #12306b;
    box-shadow: none;
}
.buyer-form-data .form-group .form-control::placeholder {
  color: #12306b;
}
.buyer-form-data .form-group :focus{
    background-color: #eaf0fa;  
    
    
   
}
.buyer-form-data .form-control :focus {
    border: #012060;
    border-radius: 10px;
    background-color: #eaf0fa; 
   
}

.buyer-form-data .form-group .form-control:-ms-input-placeholder {
 color: #12306b;
}

.buyer-form-data .form-group .form-control::-ms-input-placeholder {
 color: #12306b;
}
.buyer-form-data .form-group label{
    color: #12306b;
    font-weight: 600;
    font-size: 18px;
}
.buyer-form-data .form-group label.same-add {   
    line-height: normal;
    margin: 0;
    vertical-align: middle;
}
.buyer-form-data .form-group input#Address{
    width: 20px;
    height: 20px;
    vertical-align: middle;
}
.form-check-label-main{
    font-size: 18px;
    color: #12306b;
    font-weight: 600;
    margin-right: 20px ;
}
 .form-check-input {
    width: 20px;
    height: 20px;
}
 .form-check-label {
    font-size: 18px;
    color: #12306b;
    font-weight: 600;
}
.proceed-button{
    text-align: center;
    margin: 10px 0;
}
.proceed-button .proceed-btn{
    background-color: #12306b;
    color: #ffffff;
    border: 0;
    border-radius: 30px;
    padding: 12px 52px;
    font-size: 18px;
    text-transform: uppercase;
}
.buyer-detail{
    padding: 0 150px;
    margin-top: 20px;
}
.buyer-detail-form{
    padding: 0 150px;
}
.loan-section {
    margin: 15px 0;
}
.loan-section p {
    display: inline-block;
    margin-right: 20px;
    border: 1px solid #12306b;
    color: #12306b;
    padding: 0 10px;
    width: 221px;
}

.applicant-button{
    background-color: #012060;
    color: white;
    border-radius: 10px;
    cursor: pointer;
    margin: 5px;
    padding: 5px;
}



  /* buy now page */
  .select-unit{
    border: 2px solid #012060;
    /* width: 100% ;
    height: auto ;
    overflow: hidden;
    object-fit: contain; */
  }
  
 .select-unit  img{
    width: 100% !important ;
    height: 300px !important;
    border: none !important;
    object-fit: cover !important;
 }
  .unit-layout{
    border: 2px solid #012060;
    width: 100% ;
    /* height: 500px ; */
    object-fit: cover;
    overflow: hidden;
  }
  .unit-layout img{
    width: 100% !important ;
    height: 300px !important;
    border: none !important;
    object-fit: cover !important;
 }
  .select-criteria{
    margin-bottom: 20px;
  }
  .avaibility-detail .row{
    padding: 10px;
  }
  .select-criteria .row{
    margin-bottom: 20px;
    gap: 10px;
    padding: 10px;
  }
  .criteria-selection{
    
    border: 1px solid #012060;
    border-radius: 10px;
    background-color: #ffffff;
    /* box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2); */
    /* padding: 1rem; */
    font-size: small;
    
  }
  .checkbox-criteria {
    display: flex;
    gap: 10px;
    padding-left: 10px;
  }
  .checkbox-criteria label{
    margin-bottom: 0;
  }
  .select-criteria h5{
    color: #12306c;
  }
  .select-criteria label{
    display: block;
    color: #12306c;
    font-size: 14px;
    font-weight: 500;
  }
  .select-criteria select{
    width: 100%;
    border: 1px solid #12306c;
    border-radius: 10px;
    color: #12306c;
  }
  .avaibility-detail .confermation-main {
    margin-top: 20px;
}
  .confermation-main {
    margin-top: 50px;
}
.confermation-detail {
    border: 1px solid #707070;
    padding: 20px;
    border-radius: 10px;
}
.confermation-detail h4{
    color: #12306c;
    margin-bottom: 20px;
}
.confermation-detail p{
    color: #12306c;
    font-weight: 400;
    font-size: 20px;
    padding-left: 10px;
}
.confermation-btn ul li{
    display: inline-block;
    margin-right: 15px;
}
.confermation-btn {
    margin-left: 30px;
    margin: 20px;
}
.confermation-btn ul li a{
    background-color: #12306b;
    color: #ffffff;
    font-size: 24px;
    text-decoration: none;
    font-weight: 600;
    padding: 15px 20px;
    display: inline-block;
    border-radius: 15px;
}
.filtered-units{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    
}
.filtered-units .selected p{
    color:  white;
}
.filtered-units :hover{
    cursor: pointer;
}
.selected-units{
    margin: 5px;
    max-width: 160px;
    border-radius: 10px;
    background-color: #faf8f8;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
    padding: 5px;
}
.selected-units p{
    margin-bottom: 5px;
}
.selected{
    color: white;
    background-color: #012060;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
}
.loan-section{
    justify-content: space-between;
}
.loan-section .form-group{
    padding-left: 0;
}

.buyerForm h4{
    margin-top: 30px;
}

.select-mt{
    margin-top: 20px;
}
.unit-layout{
    margin-top: 30px;
}
.price-component-detail h4{
    color: #12306c;
    /* font-size: 28px; */
    /* font-weight: 600; */
    padding: 10px;
    
}
.price-component {
    border: 1px solid #8a8a8a;
    border-radius: 10px;
}
.price-component-detail ul li {
    color: #12306c;
    font-size: 18px;
    font-weight: 400;
    margin-left: 40px;
    list-style-type: auto;
    
} 



.price-component-detail ul li p span {
    text-align: right;
    float: right;
    width: 160px;
}

.unit-block-confermation{
    margin: 50px 100px;
    padding: 20px;
}
.unit-block-confermation .unit-conformation-detail .block-confirmation-right p{
    color: #12306b;
    font-size: 18px;
}

.payment-terms-detail{
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
}
.payment-terms-detail ul li{
    color: #12306c;
    font-size: 20px;
    font-weight: 400;
    margin-left: 40px;
    list-style-type: auto;
}
.payment-terms-detail h4{
    color: #12306c;
    
    margin-bottom: 20px;
    /* font-size: 28px; */
}
.payment-terms-detail h5{
    color: #12306c;
    margin-top: 25px;
}

/* buyer form  */


.buyer-detail .booking-details{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media (max-width:576px) {
    .buyerForm .buyer-detail, .buyer-detail-form{
        padding-inline: 0px;
       margin-top: 0;
    }
    .filtered-units{
        grid-template-columns: repeat(2, 1fr) !important;
      }
      .step-label {
        font-size: 10px !important;
      }
      .buyerForm .buyer-detail h2{
        font-size: 16px;
        margin-bottom: 0;
      }
}

@media (max-width:768px) {
    .select-unit  img{
        height: 150px !important;
     }
     .unit-layout  img{
        height: 150px !important;
     }
     .buyer-detail-text h3{
        font-size: 20px;
    }
    .buyerForm .buyer-detail, .buyer-detail-form{
        padding-inline: 10px;
      
    }
    .buyer-detail .booking-details{
        margin-top: 10px;
    }
    .buyerForm .buyer-detail .row{
        align-self: center;
    }
    .select-criteria .row{
       flex-direction: row;
    }
    .loan-section{
        flex-direction: column;
    }
    .buyerForm .buyer-detail h2{
        font-size: 18px;
    }
    .buyerForm .buyer-detail-form label{
        margin-bottom: 0;
    }
    .confermation-detail{
        padding: 10px;
    }
    .confermation-detail p{
       font-size: 16px;
    }
    .confermation-detail h4{
        margin-bottom: 10px;
    }
    .unit-layout{
        height: auto ;
      }
      .selected-units{
        width: auto;
      }
      .filtered-units{
        grid-template-columns: repeat(3, 1fr);
      }
      .price-component-detail ul li {
        font-size: 16px;
        
    } 
    .price-component-detail ul li p{
        display: flex;
        justify-content: space-between;
    }
    .payment-terms-detail{
        padding: 10px;
    }
    .payment-terms-detail ul li{
        font-size: 16px;
    }
    .payment-terms-detail h4{
        margin: 0;
    }
    .payment-terms-detail h5{
        font-size: 18px;
        margin-top: 0;
    }
    .confermation-btn ul li a{
        padding: 10px;
        font-size: 20px;
    }
    .confermation-btn{
        margin-bottom: 0;
    }
    .confermation-btn ul{
        margin-bottom: 0;
    }
    .buyer-detail .buyer-detail-image img{
        width: 100% !important ;
        height: 100px !important;
        object-fit: cover !important;
        border: 2px solid #012060;
        border-radius: 10px;
        
    }
    .buyer-form-data .form-group label{
        font-size: 16px;
    }
}
@media (max-width:1280px) {
    .buyerForm .buyer-detail, .buyer-detail-form{
        padding-inline: 10px;
    }
}



select {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    color: #333;
  }
  
  
