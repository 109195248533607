/* .loan .loan-form{
    display: grid;
    gap: 20px;
    grid-template-columns:  repeat(2, 1fr);
} */
.loan {
    margin-block: 40px;
    background-color: #e1e0ee;
    padding: 20px;
    background: linear-gradient(to top, rgb(67, 166, 205), rgb(69,214,202));
    border-radius: 10px;
    
}

@media (max-width:768px) {
    .loan-page{
        margin: 20px;
    }
    .loan-page .add-project{
        margin-block: 0;
    }
    
}